.video-container {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 aspect ratio */
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 768px) {
  .video-container {
    margin-bottom: 50px; /* 16:9 aspect ratio */
  }
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
