.will-container {
  display: flex;
  justify-content: center;
  background-color: #198754;
  margin-top: 100px;
  margin-bottom: 100px;
  color: white;
  margin-left: 30px;
  margin-right: 30px;
  border-radius: 5px;
}

.will-button {
  display: flex;
  /* justify-content: center; */
  margin-top: 20px;
}

.sub-title{
  font-size: 32px;
}

.will-title{
  font-size: 62px;
  font-weight: bolder;
}

.contact-button {
  background-color: #2f2f2f;
  color: #fff;
  border: none;
  border-radius: 25px;
  padding: 15px 30px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s ease-out;
  text-decoration: none; /* add this to remove underline */
}

.contact-button:hover {
  background-color: #1f668f;
  color: rgb(220, 220, 220);
}



.will {
  display: flex;
  align-items: center;
  width: 80%;
  margin: 60px;
}

.will-image {
  flex-basis: 50%;
  text-align: center;
}

.will-image img {
  max-width: 100%;
  height: auto;
}

.will-content {
  flex-basis: 50%;
  padding: 40px;
  /* text-align: center; */
}

@media screen and (max-width: 768px) {
  .will {
    flex-direction: column;
    align-items: stretch;
  }

  .will-image {
    flex-basis: 100%;
  }

  .will-content {
    flex-basis: 100%;
  }
  .will-container {
    /* display: flex; */
    justify-content: center;
    background-color: #198754;
    margin-top: 100px;
    margin-bottom: 100px;
    color: white;
    margin-left: 0px;
    margin-right: 0px;
  }
  .sub-title{
    font-size: 18px;
  }
  
  .will-title{
    font-size: 32px;
    font-weight: bolder;
  }
}
