@import url('https://fonts.googleapis.com/css2?family=Michroma&display=swap');

.project-title{
  font-size: 100px;
  color: green;
  font-weight: bold;
  font-family: 'Michroma', sans-serif;
  padding: 30px;
}


.image-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  margin-left: 100px;
  margin-right: 100px;
  margin-bottom: 50px;
}

@media only screen and (max-width: 768px) {
  .image-grid {
    grid-template-columns: repeat(1, 1fr);
    margin-left: 20px;
  margin-right: 20px;
  }
}

.image-wrapper {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
}

.image {
  display: block;
  width: 100%;
  height: 350px;
  object-fit: cover;
  border-radius: 15px;
  transition: transform 0.2s ease-out;
}

.image-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.747);
  color: #fff;
  padding: 10px;
  border-radius: 0px 0px 15px 15px;
}

.image-title {
  margin: 0;
}

.image-description {
  margin: 0;
  font-size: 14px;
}

.middle-image .image {
  height: 300px;
  transition: transform 0.2s ease-out;
}

.middle-image .image:hover {
  transform: scale(1.1);
}

.image:hover {
  transform: scale(1.1);
}
