.Navigation {
  font-weight: bold;
}

@media (max-width: 767px) {
  .navbar-nav {
    text-align: center;
  }

  .navbar-nav .nav-link {
    display: block;
    margin-bottom: 10px;
    font-size: 20px;
    outline: none;
  }
  
}
