.footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  background-color: #f5f5f5;
  
}

.footer-column {
  width: calc(100% / 3);
  margin-bottom: 20px;
}

.footer-column h3 {
  font-size: 20px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 10px;
}

.footer-column ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer-column li {
  font-size: 16px;
  margin-bottom: 5px;
}

@media only screen and (max-width: 768px) {
  .footer {
    flex-direction: column;
    align-items: center;
    margin-top: 500px;
  }

  .footer-column {
    width: 100%;
    text-align: center;
  }

  .footer-column:not(:last-child) {
    margin-bottom: 40px;
  }
}
