.services-container {
  margin-top: 50px;
  margin-left: 100px;
  margin-right: 100px;
}

.list {
  padding: 50px;
  margin-bottom: 100px;
  margin-top: 50px;
}

.card {
  border: 1px solid #f0f0f000;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  position: relative;
  box-shadow: 0 12px 54px rgba(0, 0, 0, 0.2);
}

.card-body {
  padding: 0;
}

.card-text {
  margin-bottom: 0;
  font-size: 20px;
  line-height: 1.5;
}

.card::after {
  content: '\2713';
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  font-size: 24px;
  color: green;
}

.title {
  font-size: 60px;
  color: green;
  font-weight: bold;
  /* font-family: 'Michroma', sans-serif; */
}

.box-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.box {
  flex: 0 0 calc(25% - 20px);
  margin-bottom: 20px;
}

.box-image {
  position: relative;
  overflow: hidden;
  height: 300px;
  border-radius: 20px;
}

.box-image img {
  display: block;
  width: 100%;
  height: auto;
}

.box-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.7);
  text-align: center;
  transition: background-color 0.3s ease-out; /* add transition effect */
}

.box:hover .box-overlay {
  background-color: rgba(0, 0, 0, 0.7); /* change background color on hover */
}

.box-overlay h2 {
  margin-top: 0;
  color: black; /* default color for h2 */
  transition: color 0.3s ease-out; /* add transition effect */
}

.box:hover .box-overlay h2 {
  color: white; /* change color of h2 on hover */
}

.box-overlay p {
  margin-bottom: 0;
  color: black; /* default color for p */
  transition: color 0.3s ease-out; /* add transition effect */
}

.box:hover .box-overlay p {
  color: white; /* change color of p on hover */
}

@media screen and (max-width: 768px) {
  .box {
    flex-basis: 100%;
  }
  .title {
    font-size: 25px;
    text-align: center;
    margin-bottom: 40px;
    color: green;
    font-weight: bold;
  }
  .services-container {
    margin-top: 50px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .card::after {
    content: '\2713';
    font-size: 0px;
  }
}
