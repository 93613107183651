.contact-form {
  max-width: 600px;
  margin: 100px auto;
  padding: 40px;
  background-color: #f5f5f5;
  border-radius: 15px;
  
}

.contact-form h2 {
  text-align: center;
  font-size: 32px;
  margin-top: 0;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

input,
textarea {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.2s ease-out;
}

input:focus,
textarea:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

button[type="submit"] {
  display: block;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  background-color: #0077cc;
  border: none;
}

.success-message {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 8px 16px;
  background-color: #28a745;
  color: white;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  transition: opacity 0.3s ease;
}

.success-message::before {
  content: '\2713';
  display: inline-block;
  margin-right: 8px;
}

.success-message.show {
  opacity: 1;
}

.success-message.hide {
  opacity: 0;
}

@media only screen and (max-width: 768px) {
  .contact-form {
    max-width: 600px;
    margin-top: 0px;
    padding-top: 120px;
    background-color: #f5f5f5;
    border-radius: 15px;
    height: 100vh;
  }
}