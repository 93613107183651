.about-us {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 40px;
  background-color: #f5f5f5;
  height: 80vh;
}

.image-container {
  flex: 1;
  margin-right: 40px;
}

.image-container img {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 5%;
}

.description-container {
  flex: 2;
}

.about-title {
  font-weight: bolder;
  font-size: 52px;
  margin: 0;
}

.description {
  font-size: 23px;
  line-height: 1.7;
  margin-bottom: 20px;
}

@media only screen and (max-width: 768px) {
  .about-us {
    flex-direction: column;
    padding: 20px;
    margin-bottom: 90vh;
    height: 100vh;
  }

  .image-container {
    margin-right: 0;
    margin-bottom: 20px;
    text-align: center;
  }

  .image-container img {
    max-width: 300px;
  }
}
