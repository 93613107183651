.testimonial-slider {
  width: 100%;
  height: 100%;
  position: relative;
}

.awssld {
  position: relative;
  overflow: hidden;
  height: 40vh;
  background-color: transparent !important;
}

.awssld__content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0 auto;
  background-color: transparent !important;
}

.testimonial {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 800px;
  width: 90%;
  padding: 40px;
  background-color: #fff;
  /* height: 40vh; */
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.testimonial-image {
  flex: 1;
  margin-right: 40px;
}

.testimonial-image img {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 25px;
}

.testimonial-content {
  flex: 2;
  font-size: 20px;
  font-style: italic;
  line-height: 1.5;
}

.testimonial-content blockquote {
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
}

.testimonial-stars {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.testimonial-stars span {
  color: #ffd700;
  font-size: 24px;
}

@media only screen and (max-width: 768px) {
  .testimonial-content blockquote {
    font-size: 18px;
  }
}
