.navbar {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.nav-link {
  font-size: 0.875rem;
}

.nav-link svg {
  width: 1.5rem;
  height: 1.5rem;
}
